import Vue from 'vue';
import Vuex from 'vuex';
import { getStudentSubscribeResult } from "../api/index";
import dayjs from "dayjs";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    headerColor: '#fd6963',   //定义一个默认的颜色
    userInfo: {
      type: '',
      name: '',
      school: '',
      subscribeStatus: ''
    },
    subscribeInfo: {
      basicStatus: 0,
      advancedStatus: 0,
      basicExpireDate: "",
      advancedExpireDate: "",
    }
  },
  mutations: {
    setHeaderColor(state, color) {
      state.headerColor = color
      localStorage.setItem('headerColor', color)
    },
    setUserInfo(state, obj) {
      state.userInfo = obj
      localStorage.setItem('userInfo', JSON.stringify(obj))
    },
    setSubscribeInfo(state, obj) {
      state.subscribeInfo = obj
    },
  },
  actions: {
    updateHeaderColor(context, color) {
      context.commit('setHeaderColor', color);
    },
    updateUserInfo(context, obj) {
      context.commit('setUserInfo', obj);
    },
    async getStudentSubscribeResult(context, userId) {
      const res = await getStudentSubscribeResult(userId);
      const { basic, advanced } = res.data.data || {};
      let basicStatus = 0;
      let basicExpireDate = '';
      let advancedStatus = 0;
      let advancedExpireDate = '';
      const timestamp = Date.now();

      if (basic === -1) {
        basicStatus = 0;
      } else {
        if (timestamp > basic * 1000) {
          basicStatus = 0;
        } else {
          basicStatus = 1;
          basicExpireDate = dayjs(basic * 1000).format("MM/DD/YYYY");
        }
      }
      if (advanced === -1) {
        advancedStatus = 0;
      } else {
        if (timestamp > advanced * 1000) {
          basicStatus = 0;
        } else {
          advancedStatus = 1;
          advancedExpireDate = dayjs(advanced * 1000).format("MM/DD/YYYY");
        }
      }
      const _obj = {
        basicStatus,
        basicExpireDate,
        advancedStatus,
        advancedExpireDate
      }
      context.commit('setSubscribeInfo', _obj);
    },
  }

});
//使用 subscribe 方法在 store 中添加了一个监听函数。每当 store 中的 mutation 被提交时，
store.subscribe((mutation, state) => {
  localStorage.setItem('headerColor', state.headerColor)
})
export default store;