import Vue from "vue";
import VueRouter from "vue-router";
import { getUserId } from "../utils/store.js";
import store from '../store';
import { header } from "quill/ui/icons.js";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
        path: "/login",
        name: "login",
        component: () =>
            import ("../views/login.vue"),
        meta: {
            requiresAuth: false,
            title: "SPS",
        },
    },
    {
        path: "/receipt",
        name: "Receipt",
        component: () =>
            import ("../views/receiptT.vue"),
        meta: {
            requiresAuth: true,
            title: "Receipt",
        },
    },
    {
        path: "/order",
        name: "Order",
        component: () =>
            import ("../views/order.vue"),
        meta: {
            requiresAuth: true,
            title: "Order",
        },
    },
    {
        path: "/select-pass",
        name: "Select Pass",
        component: () =>
            import ("../views/selectPass.vue"),
        meta: {
            requiresAuth: true,
            title: "Select Pass",
        },
    },
    {
        path: "/pay",
        name: "Pay",
        component: () =>
            import ("../views/pay.vue"),
        meta: {
            requiresAuth: true,
            title: "Pay",
        },
    },
    {
        path: "/register",
        name: "register",
        component: () =>
            import ("../views/register"),
        meta: {
            requiresAuth: false,
            title: "SPS",
        },
    },
    {
        path: "/",
        redirect: "/home",
        name: "index",
        component: () =>
            import ("../views/landingpage.vue"),
        meta: {
            requiresAuth: true,
            title: "SPS",
        },
        children: [{
                path: "home",
                name: "home",
                component: () =>
                    import ("../views/home.vue"),
                meta: {
                    requiresAuth: true,
                    title: "StudentArticles",
                },
            },
            {
                path: "draft",
                name: "Draft",
                component: () =>
                    import ("../views/draft.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Draft",
                },
            },
            {
                path: "article",
                name: "Article",
                component: () =>
                    import ("../views/Article.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Article",
                },
            },
            {
                path: "/edit",
                name: "edit",
                component: () =>
                    import ("../views/editArticle.vue"),
                meta: {
                    requiresAuth: true,
                    title: "editor",
                },
            },
            {
                path: "/articleDetail",
                name: "articleDetail",
                component: () =>
                    import ("../views/articleDetail.vue"),
                meta: {
                    requiresAuth: true,
                    title: "editor",
                }
            },
            {
                path: "submission",
                name: "submission",
                component: () =>
                    import ("../views/submission.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Submission",
                },
            },
            {
                path: "scoring",
                name: "scoring",
                component: () =>
                    import ("../views/scoring.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Scoring & Awards",
                },
            },
            {
                path: "sections",
                name: "sections",
                component: () =>
                    import ("../views/sections.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Sections",
                },
            },
            {
                path: "review",
                name: "review",
                component: () =>
                    import ("../views/home.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Review Cycle",
                },
            },

        ],
    },
];

const router = new VueRouter({
    linkActiveClass: "active",
    routes,
});

router.beforeEach((to, from, next) => {
    console.log(to, from)
    if (to.path === "/login") {
        next();
    } else if (from.name === 'edit') {
        store.commit('setHeaderColor', '#fd6963')
        next()
    } else {
        if (to.meta.requiresAuth && !getUserId()) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    }
});

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});

export default router;