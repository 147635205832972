<template>
  <div id="app" style="width: 100%; margin: 0; padding: 0">
    <header v-if="canShowHeader" class="head">
      <img
        src="./assets/images/title-icon.png"
        alt=""
        class="logo"
        @click="handleLogoClick"
      />
      <div class="search">
        <el-autocomplete class="inline-input">
          <!--          v-model="state2"-->
          <!--          :fetch-suggestions="querySearch"-->
          <!--          placeholder=""-->
          <!--          :trigger-on-focus="false"-->
          <!--          @select="handleSelect"-->
          <el-button
            slot="suffix"
            type="text"
            icon="el-icon-search"
          ></el-button>
        </el-autocomplete>
      </div>
      <el-button class="logout" @click="handleLogout">Log out</el-button>
    </header>
    <router-view></router-view>
  </div>
</template>
<script>
import {
  getUserName,
  removeUserId,
  removeUserName,
  removeUserType,
} from "./utils/store";
import "./assets/common/common.css";
import "./assets/common/font.css";
import { background } from "quill/ui/icons";
import { mapState, mapActions } from "vuex";
import { getUserType } from "./utils/store";

export default {
  name: "app",
  data() {
    return {};
  },
  computed: {
    ...mapState(["headerColor"]),
    canShowHeader() {
      var show = false;
      if (this.$route.path.search(/login|register|receipt|pay/) >= 0) {
        show = true;
      }
      return !show;
    },
  },
  created() {
    // 刷新页面保存当前的颜色
    const headerColor = localStorage.getItem("headerColor");
    if (headerColor) {
      this.$store.commit("setHeaderColor", headerColor);
    }
  },
  mounted() {
    this.$UserData.user_name = getUserName();
  },
  methods: {
    ...mapActions(["updateUserInfo"]),
    handleLogout() {
      this.$confirm(
        "Are you sure you want to log out?",
        "Log Out Confirmation",
        {
          confirmButtonText: "Log Out",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.updateUserInfo(null);
          removeUserId();
          removeUserName();
          removeUserType();
          this.$router.replace("/login");
        })
        .catch(() => {});
    },
    handleLogoClick() {
      const index_path = getUserType() === "student" ? "/home" : "/submission";
      if (["/edit", "/articleDetail"].includes(this.$route.path)) {
        setTimeout(() => {
          this.$router.replace(index_path);
        }, 100);
      }
    },
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}

#app {
  padding: 0;
  margin: 0;
}

body {
  font-family: GravityNormal;
}

::-webkit-scrollbar {
  display: none;
}
</style>

<style lang="scss" scoped>
.head {
  height: 50px;
  position: relative;
  font-size: 12px;
  background-color: var(--c-primary);
  color: white;
  text-align: center;
  padding: 5px 0;

  .logo {
    width: 86px;
    height: 20px;
    margin-left: 24px;
    position: absolute;
    top: 15px;
    left: 24px;
    background-position: center;
    cursor: pointer;
  }
  .logout {
    color: #ffffff;
    position: absolute;
    right: 24px;
    top: 5px;
    background-color: var(--c-primary);
  }
}
/deep/ .search .el-input.el-input--suffix {
  input {
    &:focus {
      border: 1px solid #dcdfe6;
    }
    width: 50vw;
    height: 38px;
    background: transparent;
    border-radius: 19px;
  }
  .el-icon-search {
    color: white;
    padding-right: 10px;
  }
}

/deep/ .el-main {
  margin: 0px;
  padding: 0px;
  background: #f8f8f8;
}

.el-container {
  margin: 0px;
  padding: 0px;
}

* {
  padding: 0;
  margin: 0;
}

#app {
  padding: 0;
  margin: 0;
}
</style>
