import Vue from "vue";
import ElementUI from "element-ui";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import "tailwindcss/tailwind.css";

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

import "normalize.css";
import "./element-variables.scss";
import "@/assets/fontIcon/iconfont.css";
import UserData from "./utils/UserData";
Vue.prototype.$UserData = UserData;

import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs;

Vue.prototype.$globalMessage = new Vue();

Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");