import request from "../utils/request";

export function getQiniuToken(param) {
    return request.post("./index.php", {
        function: "getQiniuToken",
        ...param,
    });
}

export function registerStudent(params) {
    return request.post("./index.php", {
        function: "registerStudent",
        ...params
    });
}


export function getCountries() {
    return request.post("./index.php", {
        function: "getCountries",
    });
}

export function searchAccounts(search_word, account_country) {
    return request.post("./index.php", {
        function: "searchAccounts",
        search_word,
        account_country
    });
}

export function login(type, param) {
    return request.post("./index.php", {
        function: type,
        ...param
    });
}

export function createLoginOPT(email) {
    return request.post("./index.php", {
        function: "createLoginOPT",
        email,
    });
}

export function getUserArticles(user_id, content_status) {
    return request.post("./index.php", {
        function: "getUserArticles",
        user_id,
        content_status
    });
}

export function getAllArticles(content_status) {
    return request.post("./index.php", {
        function: "getAllArticles",
        content_status
    });
}

export function getArticle(id) {
    return request.post("./index.php", {
        function: "getArticle",
        id,
    });
}

export function getArticleMembers(article_id) {
    return request.post("./index.php", {
        function: "getArticleMembers",
        article_id,
    });
}

export function addArticleMember(article_id, member_email, inviter_id) {
    return request.post("./index.php", {
        function: "addArticleMember",
        article_id,
        member_email,
        inviter_id,
        role: 'member'
    });
}

export function removeArticleMember(member_id) {
    return request.post("./index.php", {
        function: "removeArticleMember",
        member_id,
    });
}

export function agreeArticleStatements(student_id, article_id) {
    return request.post("./index.php", {
        function: "agreeArticleStatements",
        student_id,
        article_id,
    });
}

export function joinArticle(member_id) {
    return request.post("./index.php", {
        function: "joinArticle",
        member_id,
    });
}

export function updatArticle(params) {
    return request.post("./index.php", {
        function: "updatArticle",
        ...params,
    });
}

export function removeArticle(id) {
    return request.post("./index.php", {
        function: "removeArticle",
        id,
    });
}

export function submitReview(article_id) {
    return request.post("./index.php", {
        function: "submitReview",
        article_id,
    });
}
export function reviewArticle(params) {
    return request.post("./index.php", {
        function: "reviewArticle",
        ...params,
    });
}

export function checkSubscriptionStatus(student_id, type) {
    return request.post("./index.php", {
        function: "checkSubscriptionStatusByType",
        student_id,
        type,
    });
}

export function getStudentSubscribeResult(student_id) {
    return request.post("./index.php", {
        function: "getStudentSubscribeResult",
        student_id
    });
}

export function getProjects() {
    return request.post("./index.php", {
        function: "getProjects",
    });
}

export function createStudentApplication(student_id, project_id) {
    return request.post("./index.php", {
        function: "createStudentApplication",
        student_id,
        project_id
    });
}

export function checkApplication(application_id) {
    return request.post('./index.php', {
        function: 'checkApplication',
        application_id
    })
}

export function getStudentSubscribeList(student_id) {
    return request.post('./index.php', {
        function: 'getStudentSubscribeList',
        student_id
    })
}

export function getPaymentApplication(application_id) {
    return request.post('./index.php', {
        function: 'getPaymentApplication',
        application_id
    })
}

export function verifyPass(pass_id, application_id) {
    return request.post('./index.php', {
        function: 'verifyPass',
        pass_id,
        application_id
    })
}

export function getStudentValidPasses(application_id) {
    return request.post('./index.php', {
        function: 'getStudentValidPasses',
        application_id
    })
}


// mock start
export function signIn(params) {
    return request.post("/mock/login", {
        ...params,
    });
}

export function sendCode(params) {
    return request.get("/mock/send-code", {
        ...params,
    });
}

export function register(params) {
    return request.post("/mock/register", {
        ...params,
    });
}
// mock end