import Cookies from 'js-cookie'

const USER_ID = 'SPS-USERID'
const USER_NAME = 'SPS-USERNAME'
const USER_TYPE = 'SPS-USER_TYPE'


export function setUserType(user_type) {
    Cookies.set(USER_TYPE, user_type)
}

export function getUserType() {
    return Cookies.get(USER_TYPE)
}

export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name)
}

export function getUserName() {
    return Cookies.get(USER_NAME)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}
export function getUserId() {
    return Cookies.get(USER_ID)
}

export function removeUserId() {
    Cookies.remove(USER_ID)
}
export function removeUserName() {
    Cookies.remove(USER_NAME)
}
export function removeUserType() {
    Cookies.remove(USER_TYPE)
}